import React from "react";
import styled from "styled-components";
import { InputWrapper } from "./Input";

const FileUploadWrapper = styled(InputWrapper)`
  input {
    line-height: 2.5rem;
  }
`;

export const FileUpload = (props: any) => (
  <FileUploadWrapper>
    <input
      {...props}
      accept="application/pdf"
      onChange={(event) => {
        return props.onChange(event.target.files[0]);
      }}
    />
  </FileUploadWrapper>
);
