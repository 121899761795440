import React from "react";
import { BlockInfo, Button, InputField } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { Container } from "../elements/Container";
import { Form } from "../elements/Form";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";

export interface FormSectionProps {
  __typename: "StrapiComponentBlocksForm";
  blockInfo: BlockInfo;
  postURL: string;
  button: Button;
  fields: InputField[];
}

export function FormSection({ section }: { section: FormSectionProps }) {
  const { blockInfo, postURL, fields, button } = section;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <Container narrow>
        <SectionTitle>{blockInfo.title}</SectionTitle>
        <SectionParagraph narrow>{blockInfo.description}</SectionParagraph>
        <Form postURL={postURL} fields={fields} buttonLabel={button?.label} />
      </Container>
    </Section>
  );
}
