import request from "axios";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { colors } from "../../styles/global";
import { BREAKPOINTS } from "../../styles/utils/media-queries";
import { InputField } from "../../types/partials";
import { ButtonPrimary, ButtonTransparent } from "./Button";
import { FileUpload } from "./FileUpload";
import Input, { ErrorLabel, InputLabel, TextAreaWrapper } from "./Input";

export const NotificationBackground = styled.div`
  background: ${colors.green};
  border: 1px solid ${colors.green};
  max-width: ${BREAKPOINTS.mobile}px;
  color: white;
  padding: 2rem 0;
  border-radius: 4px;
  text-align: center;
  font-size: 1.25rem;
  margin: 0 auto;
  p {
    margin-bottom: 1rem;
  }
`;

export const RequiredLabel = styled.span`
  margin-left: 4px;
  color: ${colors.primary};
  font-size: 1.1rem;
`;

function Notification(props) {
  const { message, buttonMessage, onClick } = props;
  return (
    <NotificationBackground>
      <p>{message}</p>
      <ButtonTransparent onClick={onClick}>{buttonMessage}</ButtonTransparent>
    </NotificationBackground>
  );
}

export function Form({ postURL, buttonLabel, fields }: { postURL: string; buttonLabel: string; fields: InputField[] }) {
  const { control, handleSubmit, formState } = useForm({ mode: "onChange" });
  const [sent, setSent] = React.useState(false);

  async function postRequest(data) {
    console.log(data);
    try {
      const fd = new FormData();
      Object.keys(data).forEach((field) => {
        fd.append(field, data[field]);
      });

      await request.post(postURL, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSent(true);
    } catch (err) {
      console.log("something went wrong", err);
    }
  }

  if (sent) {
    return (
      <Notification
        message="Your message was sent, we'll get back to you shortly."
        buttonMessage="Send new message"
        onClick={() => setSent(false)}
      />
    );
  }

  return (
    <form
      onSubmit={handleSubmit(postRequest)}
      style={{ display: "flex", flexDirection: "column", maxWidth: 767, margin: "0 auto" }}
    >
      {fields.map((fieldData) => {
        if (fieldData.type === "text") {
          return (
            <Controller
              key={fieldData.requestId}
              rules={{ required: fieldData.required && fieldData.errorMessage }}
              name={fieldData.requestId}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <InputLabel htmlFor={fieldData.requestId}>
                    {fieldData.label}
                    {fieldData.required && <RequiredLabel>*</RequiredLabel>}
                  </InputLabel>
                  <Input error={!!fieldState.error} placeholder={fieldData.placeholder} {...field} />
                  {!!fieldState.error && <ErrorLabel>{fieldState.error?.message}</ErrorLabel>}
                </>
              )}
            />
          );
        }
        if (fieldData.type === "phone") {
          return (
            <Controller
              key={fieldData.requestId}
              rules={{
                required: fieldData.required && fieldData.errorMessage,
                pattern: {
                  value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                  message: "Please enter a valid phone number",
                },
              }}
              name={fieldData.requestId}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <InputLabel htmlFor={fieldData.requestId}>
                    {fieldData.label}
                    {fieldData.required && <RequiredLabel>*</RequiredLabel>}
                  </InputLabel>
                  <Input type="tel" error={!!fieldState.error} placeholder={fieldData.placeholder} {...field} />
                  {!!fieldState.error && <ErrorLabel>{fieldState.error?.message}</ErrorLabel>}
                </>
              )}
            />
          );
        }
        if (fieldData.type === "email") {
          return (
            <Controller
              key={fieldData.requestId}
              rules={{
                required: fieldData.required && fieldData.errorMessage,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Please enter a valid e-mail address",
                },
              }}
              name={fieldData.requestId}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <InputLabel htmlFor={fieldData.requestId}>
                    {fieldData.label}
                    {fieldData.required && <RequiredLabel>*</RequiredLabel>}
                  </InputLabel>
                  <Input type="email" error={!!fieldState.error} placeholder={fieldData.placeholder} {...field} />
                  {!!fieldState.error && <ErrorLabel>{fieldState.error?.message}</ErrorLabel>}
                </>
              )}
            />
          );
        }
        if (fieldData.type === "longText") {
          return (
            <Controller
              key={fieldData.requestId}
              rules={{ required: fieldData.required && fieldData.errorMessage }}
              name={fieldData.requestId}
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <InputLabel htmlFor={fieldData.requestId}>
                    {fieldData.label}
                    {fieldData.required && <RequiredLabel>*</RequiredLabel>}
                  </InputLabel>
                  <TextAreaWrapper error={!!fieldState.error}>
                    <textarea rows={8} placeholder={fieldData.placeholder} {...field} />
                  </TextAreaWrapper>
                  {!!fieldState.error && <ErrorLabel>{fieldState.error?.message}</ErrorLabel>}
                </>
              )}
            />
          );
        }
        if (fieldData.type === "fileUpload") {
          return (
            <Controller
              key={fieldData.requestId}
              rules={{ required: fieldData.required && fieldData.errorMessage }}
              name={fieldData.requestId}
              control={control}
              render={({ field: { value, ...rest }, fieldState }) => (
                <>
                  <InputLabel htmlFor={fieldData.requestId}>
                    {fieldData.label}
                    {fieldData.required && <RequiredLabel>*</RequiredLabel>}
                  </InputLabel>
                  <FileUpload type="file" placeholder={fieldData.placeholder} {...rest} />
                  {!!fieldState.error && <ErrorLabel>{fieldState.error?.message}</ErrorLabel>}
                </>
              )}
            />
          );
        }
      })}
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <ButtonPrimary disabled={!formState.isValid} type="submit">
          {buttonLabel}
        </ButtonPrimary>
      </div>
    </form>
  );
}
