import React from "react";
import styled from "styled-components";
import { colors, sizes } from "../../styles/global";

const iconSizes = {
  [sizes.mini]: "24px",
  [sizes.tiny]: "32px",
  [sizes.small]: "40px",
  [sizes.medium]: "60px",
  [sizes.large]: "80px",
  [sizes.big]: "100px",
  [sizes.huge]: "120px",
  [sizes.massive]: "140px",
};

const bubbleSizes = {
  [sizes.mini]: 5,
  [sizes.tiny]: 8,
  [sizes.small]: 9,
  [sizes.medium]: 10,
  [sizes.large]: 13,
  [sizes.big]: 15,
  [sizes.huge]: 18,
  [sizes.massive]: 20,
};

const distanceBubbles = {
  [sizes.mini]: 2,
  [sizes.tiny]: 4,
  [sizes.small]: 5,
  [sizes.medium]: 6,
  [sizes.large]: 8,
  [sizes.big]: 10,
  [sizes.huge]: 12,
  [sizes.massive]: 14,
};

interface LoadingIconProps {
  size?: keyof typeof sizes;
  style?: object;
  ariaHidden?: boolean;
}

const LoadingIconWrapper = styled.div.attrs(({ size = "medium" }: LoadingIconProps) => ({ size }))<LoadingIconProps>`
  display: inline-block;
  position: relative;
  width: ${(props) => iconSizes[props.size]};
  height: ${(props) => iconSizes[props.size]};

  div {
    position: absolute;
    top: calc(50% - ${(props) => bubbleSizes[props.size] / 2}px);
    width: ${(props) => bubbleSizes[props.size]}px;
    height: ${(props) => bubbleSizes[props.size]}px;
    border-radius: 50%;
    background: ${colors.text};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: ${(props) => distanceBubbles[props.size]}px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  div:nth-child(2) {
    left: ${(props) => distanceBubbles[props.size]}px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(3) {
    left: ${(props) => distanceBubbles[props.size] * 4}px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  div:nth-child(4) {
    left: ${(props) => distanceBubbles[props.size] * 7}px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(${(props) => distanceBubbles[props.size] * 3}px, 0);
    }
  }
`;

function LoadingIcon(props: LoadingIconProps) {
  return (
    <LoadingIconWrapper {...props} ariaHidden>
      <div />
      <div />
      <div />
      <div />
    </LoadingIconWrapper>
  );
}

export default LoadingIcon;
