import React from "react";
import styled, { css } from "styled-components";

const FadeIn = React.forwardRef(({ className, children }: any, ref: any) => (
  <div className={className} ref={ref}>
    {children}
  </div>
));

const StyledFadeIn = styled.div<any>`
  opacity: 0;
  transform: translateY(4rem);
  visibility: hidden;
  transition: opacity 0.8s ease-out, transform 0.5s ease-out;
  will-change: opacity, visibility;

  ${({ isVisible }) => isVisible && FadeInVisible}
`;

const FadeInVisible = css`
  opacity: 1;
  transform: none;
  visibility: visible;
`;

export function FadeInWrapper(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) setVisible(true);
      });
    });
    observer.observe(domRef.current);
    return () => {
      if (domRef.current) observer.unobserve(domRef.current);
    };
  }, []);

  return (
    <StyledFadeIn ref={domRef} isVisible={isVisible}>
      {props.children}
    </StyledFadeIn>
  );
}
