import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/global";
import { maxMedia } from "../../styles/utils/media-queries";
import { Button } from "./Button";

const AccordionBackground = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  transition: all 200ms cubic-bezier(0.7, 0, 0.4, 1);

  li:not(:last-of-type) {
    border-bottom: 1px solid ${colors.borderTertiary};
    margin: 0;
  }
`;

const AccordionItem = styled.div`
  color: ${colors.textTertiary};
  display: block;
  font-size: 1em;
  line-height: 1.4em;
  margin: 0;
  text-align: left;
  text-decoration: none;
  padding: 12px 4px;
  font-style: italic;
`;

const FeatureCardBackground = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;
  text-align: left;
  border-radius: 16px;
  border: 1px solid ${colors.borderSecondary};
  padding: 1.8rem;
  transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;
  margin: 1rem;

  ${Button} {
    display: none;
  }

  :focus,
  :hover {
    box-shadow: 0 2px 4px 0 rgba(60, 64, 67, 0.3), 0 6px 11px 5px rgba(60, 64, 67, 0.15);
    transform: translateY(-4px);
  }

  :focus,
  :hover ${AccordionItem} {
    /* color: ${colors.primary}; Change color on bullet points when hovering */
  }

  ${maxMedia.mobile`

    ${Button} {
      display: block;
    }

    margin: 0;
    border-radius: 0;
    border: none;
    border-top: 1px solid ${colors.borderSecondary};
    box-shadow: none;

    :focus,
    :hover {
      box-shadow: none;
      transform: none;
    }
  `}
`;

const FeatureCardTitle = styled.div`
  display: flex;
  margin-top: 2px;

  img {
    height: 44px;
    width: 44px;
    align-self: center;
    margin-bottom: 1rem;
  }

  h3 {
    align-self: center;
    font-weight: 600;
    font-size: 1.4em;
    margin-top: 0;
    margin-left: 2rem;
    margin-bottom: 1rem !important;
    color: ${colors.ipsred};
  }
`;

const FeatureCardOverview = styled.div`
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.8rem;
  margin: 0 0 16px 0;
  color: ${colors.textSecondary};

  ${maxMedia.mobile`
    margin-left: 0;
  `}
`;

export function Accordion(props) {
  const { items = [], maxItems = 3 } = props;
  return (
    <AccordionBackground>
      {items.slice(0, maxItems).map((item, idx) => (
        <li key={idx}>
          <AccordionItem>{item}</AccordionItem>
        </li>
      ))}
    </AccordionBackground>
  );
}

function FeatureCard(props) {
  const { title, overview, iconURL } = props;
  return (
    <FeatureCardBackground>
      <FeatureCardTitle>
        <img alt={`${title}-icon`} src={iconURL} />
        <h3>{title}</h3>
      </FeatureCardTitle>
      <FeatureCardOverview>{overview}</FeatureCardOverview>
      <Button style={{ width: "180px", alignSelf: "center" }}>read more</Button>
    </FeatureCardBackground>
  );
}

export default FeatureCard;
