import React from "react";
import { CallToActionCard, CallToActionCardProps } from "./CallToActionCard";
import { CardGrid, CardGridProps } from "./CardGrid";
import { ExpandableCardGrid, ExpandableCardGridProps } from "./ExpandableCardGrid";
import { FormSection, FormSectionProps } from "./FormSection";
import { ImageGrid, ImageGridProps } from "./ImageGrid";
import { ImageList, ImageListProps } from "./ImageList";
import { RegionGrid, RegionGridProps } from "./RegionGrid";
import { SeparatorSection, SeparatorSectionProps } from "./Separator";
import { SoftwareGrid, SoftwareGridProps } from "./SoftwareGrid";
import { TextBlock, TextBlockProps } from "./TextBlock";
import { TextGrid, TextGridProps } from "./TextGrid";
import { TwoColumCallToAction, TwoColumCallToActionProps } from "./TwoColumCallToAction";
import { TwoColumnHero, TwoColumnHeroProps } from "./TwoColumnHero";

type SectionPropsTypes =
  | TwoColumnHeroProps
  | CardGridProps
  | SoftwareGridProps
  | TextGridProps
  | TwoColumCallToActionProps
  | SeparatorSectionProps
  | ImageListProps
  | TextBlockProps
  | CallToActionCardProps
  | ImageGridProps
  | ExpandableCardGridProps
  | RegionGridProps
  | FormSectionProps
  | null;

interface SectionListProps {
  sections: SectionPropsTypes[];
}

function SectionList({ sections }: SectionListProps) {
  return sections.map((section, idx) => {
    if (section.__typename === "StrapiComponentBlocksTwoColumnHero") {
      return <TwoColumnHero key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksCardGrid") {
      return <CardGrid key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksSoftwareGrid") {
      return <SoftwareGrid key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksTextGrid") {
      return <TextGrid key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksTwoColumnCallToAction") {
      return <TwoColumCallToAction key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksSeparator") {
      return <SeparatorSection key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksImageList") {
      return <ImageList key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksTextBlock") {
      return <TextBlock key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksCallToActionCard") {
      return <CallToActionCard key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksImageGrid") {
      return <ImageGrid key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksExpandableCardGrid") {
      return <ExpandableCardGrid key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksRegionGrid") {
      return <RegionGrid key={idx} section={section} />;
    }

    if (section.__typename === "StrapiComponentBlocksForm") {
      return <FormSection key={idx} section={section} />;
    }

    return null;
  });
}

export default SectionList;
