import React from "react";
import List from "../elements/List";
import { Container } from "../elements/Container";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";
import TextCard from "../elements/TextCard";
import { BlockInfo } from "../../types/partials";

export interface CardGridProps {
  __typename: "StrapiComponentBlocksCardGrid";
  id: string;
  blockInfo: BlockInfo;
  cards: {
    id;
    title: string;
    description: string;
    icon: {
      file: {
        publicURL: string;
      };
    };
  }[];
}

export function CardGrid({ section }: { section: CardGridProps }) {
  const { blockInfo, cards } = section;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      <Container>
        <SectionTitle>{blockInfo.title}</SectionTitle>
        <SectionParagraph narrow style={{ marginBottom: 0 }}>
          {blockInfo.description}
        </SectionParagraph>
        {cards.length ? (
          <List columnsL={Math.min(cards.length, 3)} gap="2.4rem">
            {cards.map((card, idx) => (
              <div key={idx} style={{ display: "flex", justifyContent: "center" }}>
                <TextCard src={card.icon?.file.publicURL} title={card.title} description={card.description} />
              </div>
            ))}
          </List>
        ) : null}
      </Container>
    </Section>
  );
}
