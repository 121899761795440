import React from "react";
import styled from "styled-components";
import { FadeInWrapper } from "../../styles/utils/FadeInWrapper";
import { BlockInfo } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { Container } from "../elements/Container";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";

export const Image = styled.img`
  margin-bottom: 4rem;
  width: 100%;
  border-radius: 16px;
`;

export interface ImageListProps {
  __typename: "StrapiComponentBlocksImageList";
  blockInfo: BlockInfo;
  images: {
    file: {
      publicURL: string;
    };
  }[];
}

export function ImageList({ section }: { section: ImageListProps }) {
  const { blockInfo, images } = section;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <Container>
        {blockInfo.title && <SectionTitle>{blockInfo.title}</SectionTitle>}
        {blockInfo.description && <SectionParagraph narrow>{blockInfo.description}</SectionParagraph>}
        {!blockInfo.description && <div style={{ marginTop: "4rem" }} />}
        {images ? images.map((i, idx) => (
          <FadeInWrapper key={idx}>
            <Image src={i.file.publicURL} />
          </FadeInWrapper>
        )) : null}
      </Container>
    </Section>
  );
}
