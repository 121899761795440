import React from "react";
import styled from "styled-components";
import { maxMedia } from "../../styles/utils/media-queries";
import { useWindowSize } from "../../styles/utils/useWindowSize";
import { BlockInfo } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { Container } from "../elements/Container";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";

export const Image = styled.img`
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  border-radius: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
`;

export const Column = styled.div<any>`
  flex: ${({ numCol }) => 100 / numCol}%;
  max-width: calc(${({ numCol }) => 100 / numCol}% - 8px);
  padding: 0 4px;

  ${maxMedia.mobile`
    flex: 50%;
    max-width: calc(50% - 8px);
  `}
`;

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

export interface ImageGridProps {
  __typename: "StrapiComponentBlocksImageGrid";
  blockInfo: BlockInfo;
  columns: number;
  images: {
    file: {
      publicURL: string;
    };
  }[];
}

export function ImageGrid({ section }: { section: ImageGridProps }) {
  const size = useWindowSize();
  const { blockInfo, columns, images } = section;
  const numColumns = size.width < 600 ? 2 : columns || 4;
  const imageColumns = images ? sliceIntoChunks(images, images.length / numColumns) : null;

  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <Container>
        {blockInfo.title && <SectionTitle>{blockInfo.title}</SectionTitle>}
        {blockInfo.description && <SectionParagraph narrow>{blockInfo.description}</SectionParagraph>}
        {!blockInfo.description && <div style={{ marginTop: "4rem" }} />}
        <Row>
          {imageColumns ? imageColumns.map((col, i) => (
            <Column key={i} numCol={numColumns}>
              {col.map((img, idx) => (
                <Image key={idx} src={img.file.publicURL} />
              ))}
            </Column>
          )) : null}
        </Row>
      </Container>
    </Section>
  );
}
