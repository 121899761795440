import React from "react";
import styled from "styled-components";
import Anchor from "../elements/Anchor";
import List from "../elements/List";
import { Container } from "../elements/Container";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";
import { colors } from "../../styles/global";
import { BlockInfo } from "../../types/partials";

const Entry = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.2rem;
  font-weight: 600;
  font-size: 1.1rem;
  width: 20%;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  color: ${colors.textSecondary};
`;

export interface TextGridProps {
  __typename: "StrapiComponentBlocksTextGrid";
  blockInfo: BlockInfo;
  values: {
    id: string;
    value: string;
  }[];
}

export function TextGrid({ section }: { section: TextGridProps }) {
  const { blockInfo, values } = section;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      <Anchor id={blockInfo.anchor} />
      <Container>
        <SectionTitle>{blockInfo.title}</SectionTitle>
        <SectionParagraph narrow>{blockInfo.description}</SectionParagraph>
        <List columnsL={5} columnsM={4} columnsS={2} gap={"1.4rem"}>
          {values
            .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0))
            .map((item) => (
              <Entry key={item.id}>{item.value}</Entry>
            ))}
        </List>
      </Container>
    </Section>
  );
}
