import styled from "styled-components";
import { maxMedia } from "../../styles/utils/media-queries";
import { colors } from "../../styles/global";

interface ListProps {
  columnsL?: number;
  columnsM?: number;
  columnsS?: number;
  gap: string;
}

const List = styled.div<ListProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnsL || 3}, 1fr);
  grid-gap: ${(props) => props.gap || "0.25rem"};

  > * {
    display: flex;
  }

  /* fix for ie 11 */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;
    > * {
      display: inline-block !important;
      width: 100%;
    }
  }
  /* --- */

  h2 {
    color: ${colors.ipsred};
  }

  ${maxMedia.tablet`
    grid-template-columns: repeat(${(props) => props.columnsM || 2}, 1fr);
  `}

  ${maxMedia.mobile`
    grid-template-columns: repeat(${(props) => props.columnsS || 1}, 1fr);
  `}
`;

export default List;
