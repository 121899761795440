import { Link } from "gatsby";
import React from "react";
import { BlockInfo, Button } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { ButtonPrimary } from "../elements/Button";
import { Container } from "../elements/Container";
import { HeroCaption, HeroTextBox, HeroTitle } from "../elements/Hero";
import Section from "../elements/Section";
import { Paragraph, TitleSecondary } from "../elements/Text";
import { Video, VideoContainer, VideoWrapper } from "../elements/Video";
import Wrap from "../elements/Wrap";

export interface TwoColumCallToActionProps {
  __typename: "StrapiComponentBlocksTwoColumnCallToAction";
  id: string;
  blockInfo: BlockInfo;
  action: Button;
  placeholderImage: {
    file: {
      publicURL: string;
      internal: {
        mediaType: string;
      };
    };
  };
  image: {
    file: {
      publicURL: string;
      internal: {
        mediaType: string;
      };
    };
  };
}

export function TwoColumCallToAction({ section }: { section: TwoColumCallToActionProps }) {
  const { blockInfo, action, image, placeholderImage } = section;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <Container>
        <Wrap inverse>
          <div>
            <TitleSecondary>{blockInfo.title}</TitleSecondary>
            <Paragraph>{blockInfo.description}</Paragraph>
            <Link to={action.url}>
              <ButtonPrimary>{action.label}</ButtonPrimary>
            </Link>
          </div>
          {image.file.internal.mediaType === "video/mp4" ? (
            <VideoWrapper>
              <img src={placeholderImage?.file.publicURL} />
              <Video alt="banner-video" src={image.file.publicURL} />
            </VideoWrapper>
          ) : (
            <img src={image?.file.publicURL} />
          )}
        </Wrap>
      </Container>
    </Section>
  );
}
