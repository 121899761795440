import { faEnvelope, faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/global";

const CardBackground = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${colors.borderSecondary};
  padding: 1.8rem;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin: 1rem;
  background: ${colors.background};

  h1 {
    font-size: 1.6rem;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.2rem;
    line-height: 2.3rem;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: left;
    color: ${colors.textTertiary};
  }

  img {
    height: 32px;
    align-self: flex-end;
  }

  ul {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1rem;
    color: ${colors.text};
  }

  li {
    margin: 0.25rem 0;
    line-height: 1.8rem;
    display: flex;
  }

  i,
  svg {
    margin-right: 8px;
    align-self: center;
  }

  a:hover {
    color: ${colors.primary};
  }
`;

export function RegionCard(props) {
  const { imgURL, regionName, companyName, description } = props;
  const { onClick, selected, email, phone, website } = props;
  return (
    <CardBackground onClick={onClick} selected={selected}>
      <img src={imgURL} alt="flag" />
      <h1>{regionName}</h1>
      {true && (
        <>
          <h2>{companyName}</h2>
          <p>{description}</p>
        </>
      )}
      <ul>
        {email && (
          <li>
            <a href={`mailto:${email}`}>
              <FontAwesomeIcon icon={faEnvelope} />
              {email}
            </a>
          </li>
        )}
        {phone && (
          <li>
            <a href={`tel:${phone}`}>
              <FontAwesomeIcon icon={faPhone} />
              {phone}
            </a>
          </li>
        )}
        {website && (
          <li>
            <a href={website} rel="noopener noreferrer" target="_blank">
              <FontAwesomeIcon icon={faGlobe} />
              {website}
            </a>
          </li>
        )}
      </ul>
    </CardBackground>
  );
}
