import React from "react";
import { BlockInfo } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { Container } from "../elements/Container";
import List from "../elements/List";
import { RegionCard } from "../elements/RegionCard";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";

export interface RegionGridProps {
  __typename: "StrapiComponentBlocksRegionGrid";
  blockInfo: BlockInfo;
  regions: {
    phone: string;
    subtitle: string;
    title: string;
    websiteURL: string;
    email: string;
    description: string;
    icon: {
      file: {
        publicURL: string;
      };
    };
  }[];
}

export function RegionGrid({ section }: { section: RegionGridProps }) {
  const { blockInfo, regions } = section;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <Container>
        {blockInfo.title && <SectionTitle>{blockInfo.title}</SectionTitle>}
        {blockInfo.description && <SectionParagraph narrow>{blockInfo.description}</SectionParagraph>}
        <List columnsL={3} columnsM={2} columnsS={1} gap="1rem">
          {regions.map((region, i) => (
            <RegionCard
              key={i}
              imgURL={region.icon?.file.publicURL}
              companyName={region.subtitle}
              regionName={region.title}
              description={region.description}
              email={region.email}
              phone={region.phone}
              website={region.websiteURL}
            />
          ))}
        </List>
      </Container>
    </Section>
  );
}
