import { colors, fonts } from "../../styles/global";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { css } from "styled-components";
import LoadingIcon from "./LoadingIcon";

export const InputLabel = styled.label`
  display: block;
  font-style: inherit;
  text-transform: uppercase;
  font-size: 12px;
  color: ${colors.textTertiary};
  font-weight: 600;
  margin: 4px 0 8px 0;
  :not(:first-child) {
    margin-top: 1.4rem;
  }
`;

export const ErrorLabel = styled.label`
  color: ${colors.negative};
  margin-top: 4px;
`;

const disabledStyles = css`
  color: ${colors.textDisabled};
  background: ${colors.backgroundDisabled};

  :focus-within,
  :active,
  :focus,
  :hover {
    border: 2px solid ${colors.border};
    background: ${colors.backgroundDisabled};
  }

  input {
    color: ${colors.textDisabled};
  }
`;

const errorStyles = css`
  border: 1px solid ${colors.negative} !important;

  :focus-within,
  :active,
  :focus,
  :hover {
    border: 1px solid ${colors.negative} !important;
  }
`;

interface InputWrapperProps {
  disabled?: boolean;
  error?: boolean;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  height: 2.57143rem;
  min-width: 0;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid ${colors.borderSecondary};
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.42857;
  color: ${colors.text};
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  :focus-within,
  :active,
  :focus {
    border: 1px solid ${colors.border};
  }

  ::placeholder {
    color: ${colors.textSecondary};
    opacity: 1;
  }

  input {
    flex: 1;
    color: ${colors.text};
    border: none;
    padding: 0 1rem;
    background: none;
  }

  svg {
    height: 100%;

    :last-child {
      margin-left: 1rem;
    }

    :first-child {
      margin-right: 1rem;
    }
  }

  ${({ disabled }) => disabled && disabledStyles}
  ${({ error }) => error && errorStyles}
`;

interface InputProps {
  name?: string;
  type?: string;
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  loading?: boolean | "left" | "right";
  iconPositionLeft?: IconProp;
  iconPositionRight?: IconProp;
  innerRef?: any;
}

function Input(props: InputProps) {
  const { iconPositionLeft, iconPositionRight, loading, disabled, error, ...rest } = props;
  return (
    <InputWrapper disabled={disabled} error={error}>
      {loading && loading !== "right" && <LoadingIcon size="mini" style={{ height: "100%" }} />}
      {iconPositionLeft && (!loading || loading === "right") && <Icon icon={iconPositionLeft} />}
      <input disabled={disabled} {...rest} />
      {iconPositionRight && loading !== "left" && <Icon icon={iconPositionRight} />}
      {loading === "right" && <LoadingIcon size="mini" style={{ height: "100%" }} />}
    </InputWrapper>
  );
}

export default Input;

export const TextAreaWrapper = styled.div<any>`
  display: flex;
  textarea {
    flex: 1;
    font-family: ${fonts.text};
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid ${colors.borderSecondary};
    font-size: 16px !important;
    padding: 0.5rem 1rem;
    line-height: 1.42857;
    color: ${colors.font};
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

    :active,
    :focus {
      border: 1px solid ${colors.border};
    }

    ::placeholder {
      color: #999;
      opacity: 1;
    }

    ${({ error }) => error && errorStyles}
  }
`;
