import { Link } from "gatsby";
import React from "react";
import { FadeInWrapper } from "../../styles/utils/FadeInWrapper";
import { BlockInfo, Software } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { Container } from "../elements/Container";
import FeatureCard from "../elements/FeatureCard";
import List from "../elements/List";
import Section, { SectionTitle } from "../elements/Section";

function sluggify(str = "") {
  return str
    .toLowerCase()
    .split(" ")
    .filter((c) => c !== "-")
    .join("-");
}

export interface SoftwareGridProps {
  __typename: "StrapiComponentBlocksSoftwareGrid";
  id: string;
  info: BlockInfo;
  softwareCard: {
    software: Software;
  }[];
}

export function SoftwareGrid({ section }: { section: SoftwareGridProps }) {
  const { info, softwareCard } = section;
  return (
    <Section
      paddingTop={info.paddingTop}
      paddingBottom={info.paddingBottom}
      primary={info.background === "primary"}
      s
      secondary={info.background === "grey"}
    >
      <Anchor id="software" />
      <Container>
        <SectionTitle>{info.title}</SectionTitle>
        {softwareCard.length ? (
          <List gap="1rem">
            {softwareCard.map(({ software }, idx) => (
              <FadeInWrapper key={idx}>
                <Link to={sluggify(software.name)}>
                  <FeatureCard
                    iconURL={software.icon?.file.publicURL}
                    title={software.name}
                    overview={software.summary}
                  />
                </Link>
              </FadeInWrapper>
            ))}
          </List>
        ) : null}
      </Container>
    </Section>
  );
}
