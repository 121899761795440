import React from "react";
import styled from "styled-components";
import Section from "../elements/Section";

export const Separator = styled.div`
  width: 92px;
  height: 6px;
  left: 561px;
  top: 683px;
  background: linear-gradient(94.89deg, #c33339 31.46%, #a91c24 100.52%);
  border-radius: 4px;
`;

export interface SeparatorSectionProps {
  __typename: "StrapiComponentBlocksSeparator";
  color: string;
  background: string;
}

export function SeparatorSection({ section }: { section: SeparatorSectionProps }) {
  return (
    <Section style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
      <Separator />
    </Section>
  );
}
