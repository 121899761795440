import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/global";
import { BREAKPOINTS } from "../../styles/utils/media-queries";

export const Image = styled.img`
  overflow: hidden;
  vertical-align: middle;
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 140px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const PrimaryCardBackground = styled.div`
  border-radius: 16px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: all 200ms cubic-bezier(0.7, 0, 0.4, 1);

  :focus,
  :hover {
    box-shadow: 0 2px 4px 0 rgba(60, 64, 67, 0.3), 0 6px 11px 5px rgba(60, 64, 67, 0.15);
    transform: translateY(-4px);
  }
`;

const TextBackground = styled.div`
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  min-height: 180px;
  padding: 24px;
  text-align: center;
  color: white;
  background: ${colors.primary};

  h3 {
    font-size: 1.8rem;
    margin-bottom: 16px;
  }

  p {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

export const PrimaryCard = ({ src, title, description, onClick }: any) => {
  return (
    <PrimaryCardBackground onClick={onClick}>
      <Image src={src} />
      <TextBackground>
        <h3>{title}</h3>
        <p>{description}</p>
      </TextBackground>
    </PrimaryCardBackground>
  );
};

const ExpandedCardBackground = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-width: ${BREAKPOINTS.mobile}px;
  margin: 0 auto;
  position: relative;
`;

const ExpandedCardContent = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  min-height: 120px;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 16px;
  }

  p {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
`;

export const ExpandedCard = ({ src, title, description, onClose }: any) => {
  return (
    <ExpandedCardBackground>
      <Image src={src} />
      <ExpandedCardContent>
        <div>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
        <CloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
      </ExpandedCardContent>
    </ExpandedCardBackground>
  );
};

export const CloseButton = styled.button`
  transition: all 200ms cubic-bezier(0.7, 0, 0.4, 1);
  background: ${colors.primary};
  color: white;
  border-radius: 40px;
  border: 0px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  :focus,
  :hover {
    box-shadow: 0 2px 4px 0 rgba(60, 64, 67, 0.3), 0 6px 11px 5px rgba(60, 64, 67, 0.15);
    transform: translateY(-4px);
  }
`;
