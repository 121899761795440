import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { FadeInWrapper } from "../../styles/utils/FadeInWrapper";
import { BlockInfo, Button } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { ButtonPrimary } from "../elements/Button";
import { Container } from "../elements/Container";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";

const Card = styled.div`
  align-self: center;
  background: white;
  padding: 4rem;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(60, 64, 67, 0.3), 0 6px 11px 5px rgba(60, 64, 67, 0.15);
`;

export interface CallToActionCardProps {
  __typename: "StrapiComponentBlocksCallToActionCard";
  blockInfo: BlockInfo;
  action: Button;
}

export function CallToActionCard({ section }: { section: CallToActionCardProps }) {
  const { blockInfo, action } = section;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <FadeInWrapper>
        <Container style={{ display: "flex", justifyContent: "center" }}>
          <Card>
            <SectionTitle>{blockInfo.title}</SectionTitle>
            <SectionParagraph narrow style={{ marginBottom: "1.4rem" }}>
              {blockInfo.description}
            </SectionParagraph>
            <Link to={action.url}>
              <ButtonPrimary>{action.label}</ButtonPrimary>
            </Link>
          </Card>
        </Container>
      </FadeInWrapper>
    </Section>
  );
}
