import styled from "styled-components";

const Anchor = styled.div`
  display: block;
  position: relative;
  top: -62px;
  visibility: hidden;
`;

export default Anchor;
