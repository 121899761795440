import React from "react";
import styled from "styled-components";
import { BlockInfo } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { Container } from "../elements/Container";
import { HeroCaption, HeroTitle } from "../elements/Hero";
import Section, { SectionParagraph, SectionTitle } from "../elements/Section";

const Card = styled.div<any>`
  ${({ show }) =>
    show
      ? `
background: white;
color: black;
padding: 2rem;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius: 4px;
    `
      : ""}
`;

export interface TextBlockProps {
  __typename: "StrapiComponentBlocksTextBlock";
  blockInfo: BlockInfo;
  hero: boolean;
}

export function TextBlock({ section }: { section: TextBlockProps }) {
  const { blockInfo, hero } = section;
  const card = false;
  return (
    <Section
      paddingTop={blockInfo.paddingTop}
      paddingBottom={blockInfo.paddingBottom}
      primary={blockInfo.background === "primary"}
      secondary={blockInfo.background === "grey"}
    >
      {blockInfo.anchor ? <Anchor id={blockInfo.anchor} /> : null}
      <Container narrow>
        <Card show={card}>
          {hero ? (
            <>
              {blockInfo.title && <HeroTitle style={{ textAlign: "center" }}>{blockInfo.title}</HeroTitle>}
              {blockInfo.description && (
                <HeroCaption narrow style={{ textAlign: "center" }}>
                  {blockInfo.description}
                </HeroCaption>
              )}
            </>
          ) : (
            <>
              <SectionTitle>{blockInfo.title}</SectionTitle>
              <SectionParagraph narrow style={{ marginBottom: 0 }}>
                {blockInfo.description}
              </SectionParagraph>
            </>
          )}
        </Card>
      </Container>
    </Section>
  );
}
