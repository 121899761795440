import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/global";
import { maxMedia } from "../../styles/utils/media-queries";

const TextCardBackground = styled.div`
  text-align: center;
  flex: 1;
  max-width: 480px;
  margin-top: 2rem;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  :not(:first-of-type) {
    margin-left: 1.2rem;
  }

  :not(:last-of-type) {
    margin-right: 1.2rem;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 2.4rem;
    margin-bottom: 1.2rem;
  }

  img {
    max-width: 100%;
    height: 140px;
  }

  p {
    line-height: 2rem;
    font-size: 1.2rem;
    color: ${colors.textSecondary};
  }

  ${maxMedia.mobile`
    margin: 0 !important;
    margin-bottom: 4rem;
  `}
`;

function TextCard(props) {
  const { title, src, description } = props;
  return (
    <TextCardBackground>
      <div style={{ height: "140px" }}>
        <img src={src} alt={`${title}-icon`} height={140} width={140} />
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
    </TextCardBackground>
  );
}

export default TextCard;
