import { Link } from "gatsby";
import React from "react";
import { BlockInfo, Button } from "../../types/partials";
import Anchor from "../elements/Anchor";
import { ButtonPrimary } from "../elements/Button";
import { Container } from "../elements/Container";
import { HeroCaption, HeroTextBox, HeroTitle } from "../elements/Hero";
import Section from "../elements/Section";
import { Video, VideoContainer } from "../elements/Video";
import Wrap from "../elements/Wrap";

export interface TwoColumnHeroProps {
  __typename: "StrapiComponentBlocksTwoColumnHero";
  id: string;
  info: BlockInfo;
  button: Button;
  placeholderImage: {
    file: {
      publicURL: string;
      internal: {
        mediaType: string;
      };
    };
  };
  image: {
    file: {
      publicURL: string;
      internal: {
        mediaType: string;
      };
    };
  };
}

export function TwoColumnHero({ section }: { section: TwoColumnHeroProps }) {
  const { info, button, image, placeholderImage } = section;
  return (
    <Section
      paddingTop={info.paddingTop}
      paddingBottom={info.paddingBottom}
      primary={info.background === "primary"}
      secondary={info.background === "grey"}
    >
      {info.anchor ? <Anchor id={info.anchor} /> : null}
      <Container>
        <Wrap inverse>
          <HeroTextBox style={{ flex: 1 }}>
            <HeroTitle>{info.title}</HeroTitle>
            <HeroCaption>{info.description}</HeroCaption>
            <Link to={button.url}>
              <ButtonPrimary>{button.label}</ButtonPrimary>
            </Link>
          </HeroTextBox>
          <div style={{ flex: 1.5, position: "relative", minHeight: "280px" }}>
            {image?.file.internal.mediaType === "video/mp4" ? (
              <VideoContainer>
                <img src={placeholderImage?.file.publicURL} />
                <Video alt="banner-video" src={image?.file.publicURL} />
              </VideoContainer>
            ) : (
              <img src={image?.file.publicURL} />
            )}
          </div>
        </Wrap>
      </Container>
    </Section>
  );
}
